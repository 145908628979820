import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const Icon = ({ type, className, size, onClick, ...restProps }) => (
  <i
    {...restProps}
    className={classnames('icon', `s-${size}`, type, className, {
      'pointer-events-none': !onClick
    })}
    onClick={onClick}
  />
);

Icon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOf([120, 80, 48, 32, 24, 20, 18, 12]),
  onClick: PropTypes.func,
  className: PropTypes.string
};

Icon.defaultProps = {
  size: 24
};

export default Icon;
