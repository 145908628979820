import axios from 'axios';
import {
  LOGIN,
  USERS,
  LOGOUT,
  USER_PROFILE,
  SET_PASSWORD,
  TOKEN,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD
} from '../constants/apiConstants';
import { BehaviorSubject, from } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

class MainService {
  constructor() {
    this._user = new BehaviorSubject(undefined);
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_ENDPOINT,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
      validateStatus: function (status) {
        return status >= 200 && status < 500;
      }
    });
    // this.getUserProfile();
  }

  checkEmail(email) {
    return this.sendRequest(`${USERS}/email/${email}`, {}, 'get', false);
  }

  forgotPassword(params) {
    return this.sendRequest(FORGOT_PASSWORD, params, 'post', false);
  }

  changePassword(params) {
    return this.sendRequest(CHANGE_PASSWORD, params, 'put', false);
  }

  login(params) {
    return this.sendRequest(LOGIN, params, 'post', false);
  }

  getUserByKey(params) {
    return this.sendRequest(`${TOKEN}/${params.token}`, {}, 'get', false);
  }

  setUserData(params) {
    return this.sendRequest(SET_PASSWORD, params, 'put', false);
  }

  register(params) {
    return this.login(params, true);
  }

  sendRequest(name, params = {}, method = 'post') {
    // const paramsKey = method === 'get' ? 'params' : 'data';

    return from(
      this.axios({
        url: name,
        method,
        ...(method === 'post' || method === 'put'
          ? { data: params }
          : { params })
      })
    ).pipe(
      map(res => {
        if (res.status === 200 || res.status === 201) {
          return {
            data: res.data || res,
            errors: null
          };
        }
        return {
          data: null,
          errors: res.data.errors,
          message: res.data.message
        };
      })
    );
  }

  updateProfile(params) {
    const user = this._user.getValue() || {};
    return this.sendRequest(USER_PROFILE, { ...user, ...params }, 'put').pipe(
      tap(data => {
        if (data?.data) {
          this._user.next(data.data);
        }
      })
    );
  }

  loginWithBetConstruct() {
    window.location.href = `${process.env.REACT_APP_ENDPOINT}/auth/bc-login`;
  }

  getUserProfile() {
    if (!this._user.getValue()) {
      this.sendRequest(USER_PROFILE, {}, 'get').subscribe(val => {
        this._user.next(val.data);
      });
    }
  }

  logout() {
    this.axios.post(LOGOUT).then(res => {
      if (res.status === 200 || res.status === 400) {
        this._user.next(null);
      }
    });
  }

  get isLoggedIn() {
    return this._user.pipe(
      filter(val => val !== undefined),
      map(val => Boolean(val))
    );
  }

  get userProfile() {
    return this._user;
  }
}

export default new MainService();
