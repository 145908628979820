import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Card from './components/Card';
import Toaster from './components/Toaster';
import MainForm from './components/mainForm';

function App() {
  // Get the current location using useLocation hook
  const location = useLocation();

  return (
    <>
      <div className="guide">
        <Card>
          <Routes>
            <Route exact path="/:page" element={<MainForm />} />
            {/* Use Navigate inside a Route */}
            <Route
              path="/"
              element={<Navigate to={`/login${location.search}`} />}
            />
          </Routes>
        </Card>
      </div>
      <Toaster />
    </>
  );
}

export default App;
