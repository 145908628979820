import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import eyeIcon from '../../assets/images/eye.svg';
import eyeIconRed from '../../assets/images/eye-red.svg';
import eyeCloseIcon from '../../assets/images/eye-close.svg';
import eyeCloseIconRed from '../../assets/images/eye-close-red.svg';

import './index.scss';

const inputConfig = {
  type: [
    'text',
    'file',
    'search',
    'color',
    'number',
    'password',
    'textarea',
    'email',
    'select'
  ]
};

const Input = React.forwardRef((props, ref) => {
  const {
    icon,
    type,
    value,
    options,
    onChange,
    readOnly,
    required,
    disabled,
    className,
    errorText,
    autoFocus,
    forceFocus,
    placeholder,
    forceFocusTrigger,
    ...restProps
  } = props;

  const [localValue, setLocalValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const isControlled = props.hasOwnProperty('value');
  const inputVal = isControlled ? value : localValue;

  const inputRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    (forceFocus || autoFocus) && inputRef.current && inputRef.current.focus();
  }, [location.pathname, inputRef, autoFocus, forceFocus, forceFocusTrigger]);

  const handleChange = e => {
    !isControlled && setLocalValue(e.target.value);
    onChange && onChange(e);
  };

  const asterisk = required ? ' *' : '';
  const modifiedPlaceholder = `${placeholder}${asterisk}`;
  const classNames = classnames(`full-width ${!readOnly && 'pr--40'}`, {
    'read-only': readOnly,
    placeholder: !value && type === 'select'
  });

  const sharedProps = {
    required,
    type: type === 'password' ? (showPassword ? 'text' : 'password') : type,
    readOnly,
    autoFocus,
    onChange: handleChange,
    className: classNames,
    placeholder: modifiedPlaceholder,
    value: inputVal,
    'aria-label': placeholder,
    ref: inputRef,
    ...restProps
  };

  const EyeImage = () => {
    if (showPassword) {
      return (
        <img
          className="icon-password absolute-position"
          alt="eye"
          src={errorText ? eyeIconRed : eyeIcon}
          width={16}
          onClick={() => setShowPassword(prev => !prev)}
        />
      );
    } else
      return (
        <img
          className="icon-password absolute-position"
          alt="eye"
          src={errorText ? eyeCloseIconRed : eyeCloseIcon}
          width={16}
          onClick={() => setShowPassword(prev => !prev)}
        />
      );
  };

  return (
    <div
      ref={ref}
      className={classnames(className, {
        disabled: disabled,
        'read-only': readOnly,
        'error-color': !!errorText
      })}
    >
      <div
        className={`card-input relative-position p--16 full-width ${
          readOnly && 'read-only'
        } ${errorText ? 'card-input-error' : ''} ${
          !errorText && !readOnly ? 'input-effect' : ''
        }`}
      >
        {icon && <Icon type={icon} size={20} />}
        {type === 'select' && !readOnly && (
          <Icon
            size={18}
            type="icon-chevron-down"
            className="input-right-action"
          />
        )}
        {type === 'select' ? (
          <select
            {...sharedProps}
            value={value}
            className={classNames}
            onChange={onChange}
          >
            {placeholder && !value && (
              <option value="">{modifiedPlaceholder}</option>
            )}
            {options?.map(option => (
              <option key={option.key} value={option.value}>
                {option.key}
              </option>
            ))}
          </select>
        ) : (
          <>
            <input {...sharedProps} autoComplete="new-password" />
            {type === 'password' && <EyeImage />}
          </>
        )}
      </div>
      <p className="error-message text-left">{errorText}</p>
    </div>
  );
});

Input.defaultProps = {
  type: inputConfig.type[0]
};

Input.propTypes = {
  icon: Icon.propTypes.type,
  type: PropTypes.oneOf(inputConfig.type),
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.any
    })
  ),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  errorText: PropTypes.string,
  autoFocus: PropTypes.bool,
  forceFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  forceFocusTrigger: PropTypes.any
};

export default Input;
