import React from 'react';
import { Link } from 'react-router-dom';

const RememberPassword = () => {
  return (
    <p className="fp-text text-left mt--12">
      Remember Password?{' '}
      <Link to="/login" className="fp-login-link bold text-center">
        Login
      </Link>
    </p>
  );
};

export default RememberPassword;
