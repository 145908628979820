import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  emailValidator,
  passwordRequiredValidator
} from '../../../../helpers/formHelper';
import Input from '../../../Input';
import clearImgRed from '../../../../assets/images/clear-red.svg';
import clearImg from '../../../../assets/images/clear.svg';
import Button from './../Button';
import continueBet from '../../../../assets/images/continue-bet.svg';
import mainService from '../../../../services/mainService';
import friendshipLogo from '../../../../assets/images/friendship-logo.svg';
import { pushToast } from '../../../Toaster';

const Login = ({ data, loading }) => {
  const { values, errors, isValid, dirty, touched, setFieldValue } = data || {};
  const { email: emailValue, password: passwordValue } = values;
  const { email: emailError, password: passwordError } = errors;
  const [hoverLoginBtn, setHoverLoginBtn] = useState(false);
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const changePassword = searchParams.get('changePassword');

  useEffect(() => {
    setTimeout(() => {
      if (changePassword?.length) {
            
        pushToast({
          title: 'Success',
          message: 'Password successfully changed!'
        });
      }
    }, 50);
  }, [changePassword]);

  
  
  return (
    <div className="full-width text-center flex-display flex-direction-column">
      <p className="card-title mb--24">Welcome to</p>
      <img
        src={friendshipLogo}
        width={266}
        alt="friendship logo"
        className="flex-align-self-center"
      />
      <div className="relative-position mt--56">
        <Field name="email" validate={emailValidator}>
          {({ field }) => (
            <>
              <Input
                {...field}
                required
                type="email"
                placeholder="Email"
                errorText={
                  emailValue && touched[field.name] && errors[field.name]
                }
              />
              {!!emailValue && (
                <img
                  src={emailError && touched.email ? clearImgRed : clearImg}
                  alt="clear"
                  width={12}
                  className="clear-icon absolute-position"
                  onClick={() => setFieldValue('email', '')}
                />
              )}
            </>
          )}
        </Field>
      </div>
      <div className="relative-position">
        <Field name="password" validate={passwordRequiredValidator}>
          {({ field }) => (
            <>
              <Input
                {...field}
                required
                type="password"
                placeholder="Password"
                errorText={
                  passwordValue && touched[field.name] && errors[field.name]
                }
              />
              {!!passwordValue && (
                <img
                  src={
                    passwordError && touched.password ? clearImgRed : clearImg
                  }
                  alt="clear icon"
                  width={12}
                  className="clear-icon absolute-position"
                  onClick={() => setFieldValue('password', '')}
                />
              )}
            </>
          )}
        </Field>
      </div>
      <p
        onClick={() => navigate('/forgot-password')}
        className="forgot-password text-left pointer-cursor mb--40"
      >
        Forgot Password ?
      </p>
      <Button
        type="submit"
        loading={loading}
        onMouseLeave={() => setHoverLoginBtn(false)}
        onMouseEnter={() => setHoverLoginBtn(true)}
        className={
          hoverLoginBtn && (!!emailValue || !!passwordValue)
            ? 'card-button-login__effect'
            : ''
        }
        disabled={(!emailValue && !passwordValue) || !dirty || !isValid}
      >
        <p>Login</p>
      </Button>
      <p className="login-text mv--16">Or</p>
      <div className="flex-display flex-justify-space-between">
        <Button
          className="card-button__bet pv--12 mr--12"
          icon={continueBet}
          onClick={() => mainService.loginWithBetConstruct()}
        >
          <p>Continue with BetConstruct</p>
        </Button>
        {/*<Button*/}
        {/*  className="card-button__guest pv--12"*/}
        {/*  icon={guestLogo}*/}
        {/*  onClick={() => {}}*/}
        {/*>*/}
        {/*  <p>As Guest</p>*/}
        {/*</Button>*/}
      </div>
    </div>
  );
};

Login.propTypes = {
  data: PropTypes.object
};

export default Login;
