import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import App from './App';

import * as serviceWorker from './serviceWorker';
import './assets/scss/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
  >
    <>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>
  </GoogleReCaptchaProvider>
);

serviceWorker.unregister();
