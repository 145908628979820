import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { passwordValidator } from '../../../../../helpers/formHelper';
import Input from '../../../../Input';
import clearImg from '../../../../../assets/images/clear.svg';
import Button from '../../Button';
import Tooltip from '../../../../Tooltip';
import mainService from '../../../../../services/mainService';
import { pushToast } from '../../../../Toaster';
import HeaderTitle from '../../HeaderTitle';
import RememberPassword from './RememberPassword';

const NewPassword = ({ data, token }) => {
  const { values, errors, isValid, dirty, setFieldValue, isEditing, touched } = data || {};

  const { createPassword: passwordValue } = values;
  const { createPassword: passwordError } = errors;
  const [hoverBtn, setHoverBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

  useEffect(() => {
    if (!passwordError) {
      setShowPasswordTooltip(false);
    }
  }, [passwordError]);

  const onChangePassword = password => {
    setLoading(true);
    mainService.changePassword({ password, token }).subscribe(res => {
      setLoading(false);
      if (!res?.data || !res?.data?.data?.signInUrl) {
        return pushToast({
          type: 'error',
          title: 'Error',
          message: res.message || 'Something went wrong'
        });
      }

      window.location.replace(`${res.data.data.signInUrl}?changePassword=success`);
    });
  };

  return (
    <div className="full-width">
      <HeaderTitle
        title="Create New Password"
        subtitle="Type your new password below"
      />
      <div className="relative-position">
        <Tooltip
          showTooltip={showPasswordTooltip}
          text={passwordValue}
          touched={touched?.createPassword}
        />
        <Field name="createPassword" validate={passwordValidator}>
          {({ field }) => (
            <>
              <Input
                {...field}
                onChange={e => {
                  field.onChange(e);
                  if (passwordError) {
                    setShowPasswordTooltip(true);
                  }
                }}
                required
                disabled={isEditing}
                type="password"
                placeholder="Password"
              />
              {!!passwordValue && (
                <img
                  src={clearImg}
                  alt="clear icon"
                  width={12}
                  className="clear-icon absolute-position"
                  onClick={() => setFieldValue('createPassword', '')}
                />
              )}
            </>
          )}
        </Field>
      </div>
      <Button
        loading={loading}
        onMouseLeave={() => setHoverBtn(false)}
        onMouseEnter={() => setHoverBtn(true)}
        className={`mt--16 ${
          hoverBtn && !!passwordValue && 'card-button-login__effect'
        }`}
        disabled={!passwordValue || !dirty || !isValid}
        onClick={() => onChangePassword(passwordValue)}
      >
        Confirm
      </Button>
      <RememberPassword />
    </div>
  );
};

NewPassword.propTypes = {
  data: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired
};

export default NewPassword;
