import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';

import { Navigate } from 'react-router-dom';
import { pushToast } from '../Toaster';
import { getUrlParameterByName } from '../../helpers/helperFunctions';
import mainService from '../../services/mainService';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ForgotPassword from './components/ForgotPassword';
import Resend from './components/ForgotPassword/components/Resend';
import NewPassword from './components/ForgotPassword/components/NewPassword';
import { PAGES } from './constants';
import './index.css';

const FormikBehavior = () => {
  const { setValues } = useFormikContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlToken = getUrlParameterByName('token', search);

  useEffect(() => {
    if (urlToken) {
      mainService.getUserByKey({ token: urlToken }).subscribe(res => {
        if (!res?.data?.data?.email) {
          navigate('/login');
        }
        const { email, firstName, lastName } = res.data.data;

        setValues({
          email,
          firstName,
          lastName
        });
      });
    }
  }, [search, setValues, urlToken, navigate]);

  return null;
};

const MainForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { page } = useParams();
  const { search } = useLocation();
  const urlToken = getUrlParameterByName('token', search);

  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  if (!Object.values(PAGES).includes(page)) {
    return <Navigate to={`/login${search}`} />;
  }

  return (
    <Formik
      initialValues={{
        password: '',
        email: '',
        name: '',
        createPassword: '',
        lastName: '',
        firstName: ''
      }}
      validateOnMount={false}
      onSubmit={async (values, { setErrors }) => {
        const token =
          process.env.REACT_APP_NODE_ENV !== 'production'
            ? process.env.REACT_APP_RECAPTCHA_TEST_TOKEN
            : await executeRecaptcha();
        const { email, password, firstName, lastName, createPassword } = values;
        switch (page) {
          case PAGES.LOGIN:
            setLoading(true);
            mainService.checkEmail(email).subscribe(res => {
              if (!res?.data) {
                setLoading(false);
                if (res.message) {
                  setErrors({ email: res.message });
                } else {
                  pushToast({
                    type: 'error',
                    title: 'Error',
                    message: 'Something went wrong'
                  });
                }
              } else {
                mainService.login({ email, password, token }).subscribe(res => {
                  if (!res?.data || !res?.data?.data?.redirectUrl) {
                    setLoading(false);
                    pushToast({
                      type: 'error',
                      title: 'Error',
                      message: res.message || 'Something went wrong'
                    });
                  } else {
                    setLoading(false);
                    window.location.href = res.data.data.redirectUrl;
                  }
                });
              }
            });
            break;
          case PAGES.SIGN_UP:
            mainService
              .setUserData({
                email,
                password: createPassword,
                firstName,
                lastName,
                token: urlToken
              })
              .subscribe(res => {
                setLoading(false);
                if (!res?.data || !res?.data?.data?.redirectUrl) {
                  pushToast({
                    type: 'error',
                    title: 'Error',
                    message: res.message || 'Something went wrong'
                  });
                } else {
                  window.location.href = res.data.data.redirectUrl;
                }
              });
            break;
          default:
          // Handle other cases here if needed
        }
      }}
    >
      {({ handleSubmit, ...formProps }) => (
        <Form
          noValidate
          className="full-width flex-display flex-direction-column flex-align-items-center"
          onSubmit={e => handleSubmit(e)}
        >
          {(() => {
            switch (page) {
              case PAGES.LOGIN:
                return <Login loading={loading} data={formProps} />;
              case PAGES.SIGN_UP:
                return <SignUp data={formProps} />;
              case PAGES.FORGOT_PASSWORD:
                return (
                  <ForgotPassword
                    data={formProps}
                    setUserEmail={setUserEmail}
                  />
                );
              case PAGES.RESEND:
                return <Resend email={userEmail} />;
              case PAGES.CHANGE_PASSWORD:
                return <NewPassword data={formProps} token={urlToken} />;
              default:
                return <Navigate to={`/login${search}`} />;
            }
          })()}
          <FormikBehavior />
        </Form>
      )}
    </Formik>
  );
};

export default MainForm;
