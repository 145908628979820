import React from 'react';
import Icon from '../../../Icon';
import PropTypes from 'prop-types';

const Button = ({
  children,
  icon,
  loading = false,
  type,
  onClick,
  className,
  disabled,
  ...props
}) => (
  <button
    type={type}
    className={`card-button flex-display flex-align-items-center flex-justify-center full-width ${
      !disabled && 'cursor-pointer'
    } pv--16 ph--32 ${
      !icon && 'card-button__login flex-justify-center uppercase bold'
    } ${className}`}
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    {icon && <img alt="sign in" className="mr--8" src={icon} />}
    {children}
    {loading && (
      <div className="ml--8">
        <Icon type="icon-spinner" />
      </div>
    )}
  </button>
);

Button.propTypes = {
  icon: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.object
};

export default Button;
