import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo.svg';
import './index.scss';

const Card = ({ children }) => (
  <ul className="card-holder">
    <li className="card">
      {children}
      <img alt="logo" src={logo} className="card-logo absolute-position" />
    </li>
  </ul>
);

Card.propTypes = {
  children: PropTypes.any
};

export default Card;
