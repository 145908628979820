import React from 'react';
import PropTypes from 'prop-types';

const HeaderTitle = ({ title, subtitle, hideText = true }) => (
  <div className="flex-display flex-direction-column text-center mb--40">
    <p className="card-title-1 bold">{title}</p>
    <p className="card-title-2 mt--12">{subtitle}</p>
    {!hideText && <p className="card-title-2 bold">Friendship</p>}
  </div>
);

HeaderTitle.propTypes = {
  hideText: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default HeaderTitle;
