import React, { useEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Notification from '../Notification';

import './index.scss';

const toastSubject = new Subject();
let toastId = 0;

export const pushToast = toast => {
  toastSubject.next({ id: toastId++, ...toast });
};

const Toaster = () => {
  const [toastsList, updateToastsList] = useState([]);
  const ref = useRef();

  const timeout = 5000;

  useEffect(() => {
    toastSubject.subscribe(addToastMessage);
  }, []);

  const addToastMessage = toast => {
    updateToastsList(arr => [{ ...toast, show: true }, ...arr]);
  };

  const hideToastMessage = (id, time = timeout) => {
    setTimeout(() => {
      updateToastsList(arr =>
        arr.map(val => (val.id === id ? { ...val, show: false } : val))
      );
    }, time);
  };

  const removeToastMessage = id => {
    setTimeout(() => {
      updateToastsList(arr => arr.filter(val => val.id !== id));
    }, timeout);
  };

  return (
    !!toastsList.length &&
    ReactDOM.createPortal(
      <ul className="toaster-holder" ref={ref}>
        {toastsList.map(notification => (
          <Notification
            key={notification.id}
            data={notification}
            onMessageHide={hideToastMessage}
            onClose={removeToastMessage}
          />
        ))}
      </ul>,
      document.body
    )
  );
};

Toaster.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.object)
};

export default Toaster;
