export const LOGIN = 'auth/sign-in';
export const SET_PASSWORD = 'auth/set-password';
export const TOKEN = '/auth/token';
export const USERS = 'users';
export const LOGOUT = 'logout';
export const REGISTRATION = 'register';
export const FORGOT_PASSWORD = 'auth/forgot';
export const RESET_PASSWORD = 'password/reset';
export const CHANGE_PASSWORD = 'auth/change-password';
export const VERIFY = 'verify';
export const USER_PROFILE = 'user';
export const API_HOST = 'https://vstreamapi.feedconstruct.com/api/feed-sso/login?redirect_url=https://friendship.feedconstruct.com';
