import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import {
  emailValidator,
  nameValidator,
  passwordValidator
} from '../../../../helpers/formHelper';
import Input from '../../../Input';
import clearImg from '../../../../assets/images/clear.svg';
import clearImgRed from '../../../../assets/images/clear-red.svg';
import Button from './../Button';
import Tooltip from '../../../Tooltip';
import HeaderTitle from '../HeaderTitle';

const SignUp = ({ data, loading }) => {
  const { isEditing, errors, isValid, dirty, touched, values, setFieldValue } =
    data || {};
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [hoverBtn, setHoverBtn] = useState(false);

  const {
    email: emailValue,
    createPassword: passwordValue,
    lastName: lastNameValue,
    firstName: firstNameValue
  } = values;
  const {
    createPassword: passwordError,
    lastName: lastNameError,
    firstName: firstNameError
  } = errors;

  useEffect(() => {
    if (!passwordError) {
        setTimeout(() => setShowPasswordTooltip(false), 2000)
    }
  }, [passwordError]);

  return (
    <div className="full-width">
      <HeaderTitle
        title="Create Account"
        subtitle="Create Password  to start using"
        hideText={false}
      />
      <div className="relative-position">
        <Field name="email" validate={emailValidator}>
          {({ field }) => (
            <Input
              {...field}
              required
              readOnly="readonly"
              disabled={isEditing}
              type="email"
              placeholder="Email"
              errorText={
                emailValue && touched[field.name] && errors[field.name]
              }
            />
          )}
        </Field>
      </div>
      <Tooltip
        showTooltip={showPasswordTooltip}
        text={passwordValue}
        touched={touched?.createPassword}
      />
      <div className="relative-position">
        <Field name="createPassword" validate={passwordValidator}>
          {({ field }) => (
            <>
              <Input
                {...field}
                onChange={e => {
                  field.onChange(e);
                  if (passwordError) {
                    setShowPasswordTooltip(true);
                  }
                }}
                required
                disabled={isEditing}
                type="password"
                placeholder="Create password"
              />
              {!!passwordValue && (
                <img
                  src={clearImg}
                  alt="clear icon"
                  width={12}
                  className="clear-icon absolute-position"
                  onClick={() => setFieldValue('createPassword', '')}
                />
              )}
            </>
          )}
        </Field>
      </div>
      <div className="relative-position">
        <Field name="firstName" validate={nameValidator}>
          {({ field }) => (
            <>
              <Input
                {...field}
                required
                disabled={isEditing}
                placeholder="Name"
                errorText={
                  firstNameValue && touched[field.name] && errors[field.name]
                }
              />
              {!!firstNameValue && (
                <img
                  src={
                    firstNameError && touched.firstName ? clearImgRed : clearImg
                  }
                  alt="clear icon"
                  width={12}
                  className="clear-icon absolute-position"
                  onClick={() => setFieldValue('firstName', '')}
                />
              )}
            </>
          )}
        </Field>
      </div>
      <div className="relative-position">
        <Field name="lastName" validate={nameValidator}>
          {({ field }) => (
            <>
              <Input
                {...field}
                required
                disabled={isEditing}
                placeholder="Surname"
                errorText={
                  lastNameValue && touched[field.name] && errors[field.name]
                }
              />
              {!!lastNameValue && (
                <img
                  src={
                    lastNameError && touched.lastName ? clearImgRed : clearImg
                  }
                  alt="clear icon"
                  width={12}
                  className="clear-icon absolute-position"
                  onClick={() => setFieldValue('lastName', '')}
                />
              )}
            </>
          )}
        </Field>
      </div>
      <Button
        type="submit"
        loading={loading}
        onMouseLeave={() => setHoverBtn(false)}
        onMouseEnter={() => setHoverBtn(true)}
        className={`mt--16 ${
          hoverBtn &&
          !(!emailValue && !passwordValue) &&
          'card-button-login__effect'
        }`}
        disabled={
          (!emailValue &&
            !passwordValue &&
            !firstNameValue &&
            !lastNameValue) ||
          !dirty ||
          !isValid
        }
      >
        Sign Up
      </Button>
    </div>
  );
};

SignUp.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool
};

export default SignUp;
