import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import mainService from '../../../../../services/mainService';
import { pushToast } from '../../../../Toaster';
import RememberPassword from './RememberPassword';

const Resend = ({ email }) => {
  const [hoverBtn, setHoverLBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onReSend = email => {
    setLoading(true);
    mainService.forgotPassword({ email }).subscribe(res => {
      setLoading(false);
      if (res?.data?.data?.success) {
        setDisabled(true);
        return pushToast({
          title: 'Success',
          message: 'Email sent successfully!'
        });
      }

      return pushToast({
        type: 'error',
        title: 'Error',
        message: res.message || 'Something went wrong'
      });
    });
  };

  return (
    <div className="flex-display flex-direction-column flex-justify-center full-width">
      <p className="card-title-1 text-center mt--12 bold">Check Your Email</p>
      <p className="card-title-2  text-center mt--12">
        The reset link has been sent to
      </p>
      <p className="card-title-2  text-center mb--24 bold ellipsis-text">
        {email}
      </p>
      <Button
        loading={loading}
        onMouseLeave={() => setHoverLBtn(false)}
        onMouseEnter={() => setHoverLBtn(true)}
        className={`mt--24 ${hoverBtn && 'card-button-login__effect'}`}
        onClick={() => onReSend(email)}
        disabled={disabled}
      >
        Resend
      </Button>
      <RememberPassword />
    </div>
  );
};

Resend.propTypes = {
  email: PropTypes.string
};

export default Resend;
