import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import { emailValidator } from '../../../../helpers/formHelper';
import Input from '../../../Input';
import clearImgRed from '../../../../assets/images/clear-red.svg';
import clearImg from '../../../../assets/images/clear.svg';
import './index.css';
import Button from '../Button';
import mainService from '../../../../services/mainService';
import { pushToast } from '../../../Toaster';
import HeaderTitle from '../HeaderTitle';
import RememberPassword from './components/RememberPassword';

const ForgotPassword = ({ data, setUserEmail }) => {
  const { values, errors, isValid, dirty, touched, setFieldValue } = data || {};
  const { email: emailValue } = values;
  const { email: emailError } = errors;
  const [hoverLoginBtn, setHoverLoginBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSend = email => {
    setLoading(true);
    mainService.forgotPassword({ email }).subscribe(res => {
      setLoading(false);
      if (!res?.data?.data.success) {
        return pushToast({
          type: 'error',
          title: 'Error',
          message: res.message || 'Something went wrong'
        });
      }
      setUserEmail(email);
      navigate('/resend');
    });
  };

  return (
    <div>
      <HeaderTitle
        title="Forgot your password?"
        subtitle="No worries! We’ll send you a reset link. Just enter your email address."
      />
      <div className="relative-position">
        <Field name="email" validate={emailValidator}>
          {({ field }) => (
            <>
              <Input
                {...field}
                required
                type="email"
                placeholder="Email"
                errorText={
                  emailValue && touched[field.name] && errors[field.name]
                }
              />
              {!!emailValue && (
                <img
                  src={emailError && touched.email ? clearImgRed : clearImg}
                  alt="clear"
                  width={12}
                  className="clear-icon absolute-position"
                  onClick={() => setFieldValue('email', '')}
                />
              )}
            </>
          )}
        </Field>
      </div>
      <Button
        loading={loading}
        onMouseLeave={() => setHoverLoginBtn(false)}
        onMouseEnter={() => setHoverLoginBtn(true)}
        className={`mt--16 ${
          hoverLoginBtn && !!emailValue ? 'card-button-login__effect' : ''
        }`}
        disabled={!emailValue || !dirty || !isValid}
        onClick={() => onSend(emailValue)}
      >
        Send Reset Link
      </Button>
      <RememberPassword />
    </div>
  );
};

ForgotPassword.propTypes = {
  data: PropTypes.object.isRequired,
  setUserEmail: PropTypes.func.isRequired
};

export default ForgotPassword;
