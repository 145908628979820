import { string } from 'yup';

const validate = (schema, value) => {
  try {
    schema.validateSync(value);
  } catch (err) {
    return err.message;
  }
};

export const nameValidator = value => {
  const schema = string()
    .min(1, 'Your name must contain at least 1 character')
    .max(50, `Your name must be under 50 character`)
    .matches(/^.*\S{2,}.*$/, `Your name must contain at least 1 character`);

  return validate(schema, value);
};

export const emailValidator = value => {
  const schema = string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      'Please provide valid email address'
    )
    .email('Please provide valid email address')
    .required('Required');

  return validate(schema, value);
};

export const passwordValidator = value => {
  const schema = string()
    .required('Required')
    .min(8, 'Your password must contain at least 8 characters')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9\W]).{8,}$/,
      'Password must contain at least one uppercase character, number and symbol'
    );
  return validate(schema, value);
};

export const passwordRequiredValidator = value => {
  const schema = string().required('Required');
  return validate(schema, value);
};

export const matchNewPasswords = (value, values) => {
  return value !== values['password'] && 'Passwords not match';
};

export const matchNewPasswordWithOld = (value, values) => {
  return (
    value === values['currentPassword'] &&
    'The current and new passwords should not be the same'
  );
};
